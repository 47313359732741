import React from 'react';
import { Link } from 'gatsby';

import pdf from '../assets/guides/Gogeta Nursery - Employer Guide.pdf';
import image from '../assets/images/employee-nursery-hero.png';
import { CustomButton } from '../components/custom-button';
import GogetaNurseryExplained from '../components/GogetaNurseryExplained';
import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { NurseryCalculatorSection } from '../sections/EmplooyeeNursery/NurseryCalculator';
import { InfoSection } from '../sections/EmployeeCycling/InfoSection';
import { BrownInfo } from '../sections/EmployerCycling/BrownInfo';
import { SignUp } from '../sections/EmployerCycling/SignUp';
import { EmployerNurseryHero } from '../sections/EmployerNursery/EmployerNurseryHero';
import { download } from '../utils/download';

const EmployerNursery = () => {
	return (
		<MainLayout
			title="Employer Nursery Scheme Benefits | Easy Setup & Staff Savings"
			description="Gogeta’s nursery scheme offers easy setup for employers, helping reduce staff childcare costs and improve retention through salary sacrifice benefits."
		>
			<ScPage>
				<EmployerNurseryHero />
				<InfoSection
					title="Same dough,<br/>more bread"
					text="With Gogeta employees can pay for nursery fees out of their gross pay, meaning more of their salary is theirs to keep."
				/>
				<NurseryCalculatorSection title={'Try it for yourself'} isItEmployee={false} />
				<GogetaNurseryExplained />
				<InfoSection
					title="FAQs"
					text="Got more questions? We’ve got you covered."
					buttons={
						<Link to={'/employer-faq/#bike-faqs/'}>
							<CustomButton>Get some answers</CustomButton>
						</Link>
					}
				/>
				<BrownInfo
					title="Get in touch"
					text="We’d love to chat. If you’re an employer we can give you a quick walkthrough of how it all works, and how Gogeta can benefit your employees."
					image={image}
					link={'https://meetings-eu1.hubspot.com/barry2/wpn-demo'}
					isBike={true}
				/>
				<InfoSection
					title="Want to find out more?"
					text="Browse our product guides for a bit more in depth info"
					buttons={
						<>
							<CustomButton
								onClick={() => {
									download(pdf as string);
								}}
							>
								Download this guide to Gogeta (Product)
							</CustomButton>
						</>
					}
				/>
				<SignUp isItBikeSite={false} />
			</ScPage>
		</MainLayout>
	);
};

export default EmployerNursery;
